import { H } from 'highlight.run';
import ReactDOM from 'react-dom';

import { buildLaunchDarklyClientSideProviderConfig } from '@assured/utilities/src/launchDarklySetup';
import * as Sentry from '@sentry/react';

import App from './App';
import MockShell from './MockShell';
// import MockShell from './MockShell';
import { LDProvider, setup as setupLD } from './services/launch-darkly';
import * as serviceWorker from './serviceWorker';

// On chunk load failures, forcibly reload the page. This should be pretty
// much transparent to the user, because chunks are only loaded on navigation
// events, so it just seems like a "weird" navigation event.
window.addEventListener('error', e => {
  if (
    /Loading chunk [\d]+ failed/.test(e.message) ||
    /Importing a module script failed/.test(e.message) ||
    /Failed to fetch dynamically imported module/.test(e.message) ||
    /not a valid JavaScript MIME type/.test(e.message)
  ) {
    window.location.reload();
  }
});

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

const useHighlight = Boolean(process.env.REACT_APP_HIGHLIGHT_PROJECT_ID);
const useSentry = Boolean(process.env.REACT_APP_SENTRY_DSN);
const isolateApp = process.env.REACT_APP_ISOLATE;

const AppShell = isolateApp ? MockShell : App;

if (useHighlight) {
  H.init(process.env.REACT_APP_HIGHLIGHT_PROJECT_ID, {
    environment:
      process.env.REACT_APP_ENVIRONMENT || process.env.DOPPLER_ENVIRONMENT,
    version: process.env.VERCEL_GIT_COMMIT_SHA,
    enableSegmentIntegration: true,
    enableStrictPrivacy: false,
    enableCanvasRecording: false,
    samplingStrategy: { canvas: 1 },
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
    },
  });
}

if (useSentry) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment:
      process.env.REACT_APP_ENVIRONMENT || process.env.DOPPLER_ENVIRONMENT,
    release: process.env.VERCEL_GIT_COMMIT_SHA,
    beforeBreadcrumb(breadcrumb) {
      if (
        ['xhr', 'fetch'].includes(breadcrumb?.category ?? '') &&
        (breadcrumb.data?.url.contains('pub.highlight.run') ||
          breadcrumb.data?.url.contains('rs.fullstory.com') ||
          breadcrumb.data?.url.contains('api.segment.io'))
      ) {
        return null;
      }
      return breadcrumb;
    },
  });
}

if (useHighlight && useSentry) {
  H.getSessionURL().then(sessionUrl => {
    Sentry.setContext('highlight', {
      url: sessionUrl,
    });
  });
}

const ldClient = setupLD(
  buildLaunchDarklyClientSideProviderConfig({
    clientSideID: process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID,
    appSlug: 'adjuster',
    appName: 'Adjuster',
  }),
);

ldClient.on('ready', () => {
  ReactDOM.render(
    <LDProvider client={ldClient}>
      <AppShell />
    </LDProvider>,
    document.getElementById('root'),
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
